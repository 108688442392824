@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  overscroll-behavior-y: contain;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

#root {
  height: 100vh;
  overflow-y: scroll;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

.options_wrapper {
  position: relative;
  padding: 1vw 0;
}

.options_wrapper::after {
  content: "";
  background: #fff;
  height: 2px;
  width: 90%;
  position: absolute;
  bottom: 0;
  left: 50%;
  translate: -50%;
  box-shadow: inset 0px 1.5px 1.5px rgba(0, 0, 0, 0.25);
}

.options_wrapper:last-child::after {
  display: none;
}

.options_label {
  background: #fdfdfd;
  border-radius: 1.125vw;
  font-weight: 500;
  font-size: 0.825vw;
  line-height: 1vw;
  display: flex;
  align-items: center;
  text-align: center;
  color: #313232;
  padding: 0.4vw 0.75vw;
  white-space: nowrap;
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
}

input[type="radio"]:checked + label {
  background: #146c78;
  color: white;
}

.inline-heart {
  display: inline;
}

.container {
  height: 100%;
}

.main {
  height: 100%;
  padding: 1.75vw 1.65vw 5vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1a1c20;
  justify-content: flex-end;
}

.language_switcher {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2vw;
  line-height: 1.45vw;
  padding: 0.35vw 1vw;
  color: #ffffff;
  background: #313232;
  border-radius: 0.75vw;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25vw;
  border: none;
  position: absolute;
  top: 1.75vw;
  right: 1.65vw;
  z-index: 2;
}

.open_menu {
  font-weight: 700;
  font-size: 2.25vw;
  padding: 1.3vw 35px;
  line-height: 2.75vw;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #313232;
  background: #ffffff;
  border-radius: 1.125vw;
  z-index: 2;
}

.video_bg {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100vh;
  top: 0;
  object-fit: cover;
}

.overlay_loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}